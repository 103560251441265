import { formatCurrency } from 'schema/price.schema';

import { lowercaseAndCapitalize } from 'util/cart';

export type ParseType = 'default' | 'capitalize';

export const parsePatientName = (name: string, parseType: ParseType = 'default') => {
    const nameToArr = name.split(',');
    if (parseType === 'capitalize') {
        const normalizeName = lowercaseAndCapitalize(name.split(',').toReversed().join(' '));
        return normalizeName;
    }
    return nameToArr.toReversed().join(' ');
};

export const getMemberInitials = (name: string) => {
    const names = parsePatientName(name).split(' ');
    if (names.length === 1) {
        return names[0][0].toUpperCase();
    }
    return (names[0][0] + names[names.length - 1][0]).toUpperCase();
};

export const hasPositiveBalance = (value: number) => value > 0;

export const hasNegativeBalance = (value: number) => value < 0;

// Positive values = DEBIT | Negative values = CREDIT
export const totalDueParser = (value: number) =>
    hasNegativeBalance(value) ? `+${formatCurrency(value * -1)}` : formatCurrency(Number(value)).replace('-', '');
