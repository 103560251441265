import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'ui-kit/button/button';

import IconComposer from 'display-components/icon-composer';

import withSkeleton from 'hoc/withSkeleton';

import {
    getMemberInitials,
    hasNegativeBalance,
    hasPositiveBalance,
    parsePatientName,
    totalDueParser
} from './balance-by-member-list.helpers';
import './balance-by-member-list.styles.scss';

export interface BalanceByMemberListProps {
    members: Record<string, any>[];
    onClickPay: (patientName: string, amount: number, epostPatientNum: string) => void;
}

const BalanceByMemberList = ({ members, onClickPay }: BalanceByMemberListProps) => {
    const { t } = useTranslation();

    // Positive values = DEBIT | Negative values = CREDIT
    return (
        <ul className="balance-by-member-list">
            {members?.length &&
                members.map(({ currentBalance, patientName, epostPatientNum, isCaregiver }) => {
                    // Don't show the member if the currentBalance is 0.
                    if (currentBalance === 0) {
                        return null;
                    }
                    const memberIconType = classNames('balance-by-member-list__fullname-container', {
                        'fullname-is-caregiver': isCaregiver
                    });
                    const totalDueVariant = classNames('balance-by-member-list__item__total-due', {
                        'total-due-refund': hasNegativeBalance(currentBalance),
                        'total-due-debt': hasPositiveBalance(currentBalance)
                    });

                    return (
                        <li className="balance-by-member-list__item" key={epostPatientNum}>
                            <div className="balance-by-member-list__item__user-info">
                                {hasNegativeBalance(currentBalance) ? (
                                    <IconComposer
                                        content={<p className={memberIconType}>{getMemberInitials(patientName)}</p>}
                                        subIconType="positive"
                                        variant="content"
                                    />
                                ) : (
                                    <p className={memberIconType}>{getMemberInitials(patientName)}</p>
                                )}
                                <div className="balance-by-member-list__item__user-info__details">
                                    <p className="balance-by-member-list__item__user-info__details__full-name">
                                        {parsePatientName(patientName)}
                                    </p>
                                    <p className={totalDueVariant}>{totalDueParser(currentBalance)}</p>
                                </div>
                            </div>
                            {hasPositiveBalance(currentBalance) && (
                                <Button
                                    className="balance-by-member-list__item__pay-btn btn-bold btn-outline"
                                    label={t('pages.profile.balanceByMember.btnAction')}
                                    type="button"
                                    variant="outline-primary"
                                    onClick={() => onClickPay(patientName, currentBalance, epostPatientNum)}
                                />
                            )}
                        </li>
                    );
                })}
        </ul>
    );
};

export default withSkeleton(BalanceByMemberList);
