import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

import { PaymentsState } from './payments.reducers';

export const paymentsStateSelector = (state: RootState): PaymentsState => state.paymentsReducers;

export const paymentsSelectedMethodSelector = createSelector(
    paymentsStateSelector,
    (payments) => payments.selectedPaymentMethod
);

export const paymentIsLoadingHistorySelector = createSelector(
    paymentsStateSelector,
    (payments) => payments.isLoadingTransactions
);
