import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';

import BalanceByMemberList from 'display-components/balance-by-member-list';
import { parsePatientName } from 'display-components/balance-by-member-list/balance-by-member-list.helpers';
import MakePayment from 'display-components/payment-history/make-payment-modal/make-payment-modal.component';
import TransactionList from 'display-components/payment-history/transaction-list';
import UserBalance from 'display-components/payment-history/user-balance';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import { TransactionCardVariant } from 'components/transaction-card/transaction-card.types';

import { accountProfileSelector } from 'state/account/account.selectors';
import { familyProfileDependentsSelector } from 'state/family-profile/family-profile.selectors';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';
import { paymentsV2GetPaymentHistoryRoutine } from 'state/payments/payments.routines';
import { paymentIsLoadingHistorySelector, paymentsStateSelector } from 'state/payments/payments.selectors';

import { PaymentHistoryV2Result } from 'types/payment-history';

import { noop, parseObject } from 'util/function';
import { convertToTitleCase } from 'util/string';

import './payment-history.style.scss';

const PaymentHistory = ({ data }: { data: GatsbyTypes.PaymentHistoryDataQuery }) => {
    // Hooks
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Local State
    const [activeTab, setActiveTab] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [showPagination, setShowPagination] = useState(true);
    const [paymentsList, setPaymentsList] = useState<PaymentHistoryV2Result[]>([]);

    // Selectors
    const { paymentHistoryV2 } = useSelector(paymentsStateSelector);
    const isLoadingPaymentHistoryData = useSelector(paymentIsLoadingHistorySelector);
    const familyProfileData = useSelector(familyProfileDependentsSelector);
    const profileObject = useSelector(accountProfileSelector);

    // Memos
    const resultsAmount = useMemo(() => {
        if (!paymentHistoryV2) return undefined;
        const resultsLeft = paymentHistoryV2.totalRecords - paymentsList.length;
        if (resultsLeft === 0) setShowPagination(false);
        return resultsLeft > 10 ? 10 : resultsLeft;
    }, [paymentsList]);

    const creditBalance = useMemo(() => {
        if (!paymentHistoryV2) return undefined;

        return paymentHistoryV2.currentBalance;
    }, [paymentHistoryV2]);

    const debitFamilyBalance = useMemo(() => {
        if (!paymentHistoryV2) return undefined;

        return paymentHistoryV2.totalBalance;
    }, [paymentHistoryV2]);

    const hasRecords = useMemo(() => {
        if (paymentHistoryV2?.totalRecords === 0) return false;

        return true;
    }, [paymentHistoryV2]);

    // This comparison determines whether the `isTotalPayment` flag should be set to `true` or `false`.
    // The flag is `true` only when:
    // - The user is a Caregiver (`profileObject?.isCaregiver` is `true`).
    // - The amount to pay includes contributions from both the owner and dependents, meaning the
    //   current balance (creditBalance) is less than the total family balance (debitFamilyBalance).
    //
    // If the current balance is equal to the total balance (`debitFamilyBalance === creditBalance`),
    // it means the payment applies only to the owner, not the family group. In this case, the flag
    // must be `false` to prevent treating an individual payment as a family payment, which could
    // cause bugs in the API.
    const isTotalPayment = useMemo(() => {
        const isTotalBalanceEqualToCurrentBalance = debitFamilyBalance === creditBalance;

        if (profileObject?.isCaregiver && !isTotalBalanceEqualToCurrentBalance) {
            return true;
        } else {
            return false;
        }
    }, [profileObject, paymentHistoryV2]);

    const handleShowMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleShowAll = () => {
        setCurrentPage(1);
        setPageSize(paymentHistoryV2?.totalRecords || 10);
        setShowPagination(false);
    };

    const fetchPaymentsHistory = useCallback(() => {
        dispatch(
            paymentsV2GetPaymentHistoryRoutine.trigger({
                page: currentPage.toString(),
                pageSize: pageSize.toString(),
                includeAging: 'false'
            })
        );
    }, [dispatch, currentPage, pageSize]);

    useEffect(() => {
        fetchPaymentsHistory();
    }, [fetchPaymentsHistory]);

    const parsePaymentHistoryV2Result = (data: Record<string, any>) => {
        const targetObject = {
            transactionInfo: {
                DMEItem: '',
                DMEOrder: '',
                EPostScriptId: '',
                GLDebitCredit: '',
                GLPaymentNumber: '',
                GLPostAmount: '',
                GLPostDatetime: '',
                GLPostNote: '',
                GLPostStatusDesc: '',
                GLPostStatusNum: '',
                GLPostType: '',
                GLPostUser: '',
                epostPatientNum: '',
                familyId: '',
                orderPaymentCardMonth: '',
                orderPaymentCardMonthNum: '',
                orderPaymentCardNumber: '',
                orderPaymentCardSeqNum: '',
                orderPaymentCardTypeDesc: '',
                orderPaymentCardTypeNum: '',
                orderPaymentCardYear: '',
                patientName: '',
                displayDateTime: '',
                displayDate: '',
                paidUsingCredit: '',
                accountsReceivableType: '',
                accountsReceivableStatus: ''
            },
            user: '',
            userType: ''
        };
        const parsedData = parseObject(targetObject, data);

        const isTransactionFromDependent = familyProfileData.find(
            (dep) => dep.ePostPatientNum === data.epostPatientNum
        );
        const isTransactionFromCurrentUser = data.epostPatientNum === profileObject?.epostPatientNum;

        // should replicate BE name convention - "LASTNAME,FISRTNAME"
        const patientName = isTransactionFromCurrentUser
            ? `${profileObject?.patientLastName},${profileObject?.patientFirstName}`
            : `${isTransactionFromDependent?.familyMemberLastName},${isTransactionFromDependent?.familyMemberFirstName}`;

        const isSoloUser = !profileObject?.isCaregiver && familyProfileData.length === 0;

        return {
            ...parsedData,
            user: isSoloUser ? '' : parsePatientName(patientName, 'capitalize'),
            userType: !isTransactionFromDependent ? 'caregiver' : 'dependent'
        };
    };

    useEffect(() => {
        if (paymentHistoryV2) {
            if (profileObject?.isCaregiver) {
                const getDependentsResults = paymentHistoryV2.dependents
                    .filter((dep) => dep.results.length > 0)
                    .map((a) => a.results)
                    .flat();
                const mergeResults = [...paymentHistoryV2.results, ...getDependentsResults];
                const sortedArray = mergeResults.sort((a, b) =>
                    a.GLPostDatetime < b.GLPostDatetime ? 1 : a.GLPostDatetime > b.GLPostDatetime ? -1 : 0
                );
                setPaymentsList(sortedArray);
            } else {
                const paymentsListDates = new Set(paymentsList.map((item) => item.GLPostDatetime));
                const newItems = paymentHistoryV2.results.filter(
                    (payment) => !paymentsListDates.has(payment.GLPostDatetime)
                );
                // If there are new items, add them to paymentsList
                if (newItems.length > 0) {
                    setPaymentsList((prevPayments) => [...prevPayments, ...newItems]);
                }
            }
        }
    }, [paymentHistoryV2]);

    const getBalanceByMemberData = useCallback(() => {
        if (!profileObject?.isCaregiver || !paymentHistoryV2) return [];

        const { isCaregiver } = profileObject;

        return [paymentHistoryV2, ...paymentHistoryV2.dependents].map((currentValue) => {
            const findFamilyData = paymentHistoryV2.dependents.find(
                (item) => item.epostPatientNum === currentValue.epostPatientNum
            );

            return findFamilyData
                ? {
                      epostPatientNum: findFamilyData.epostPatientNum,
                      currentBalance: currentValue.currentBalance,
                      patientName: convertToTitleCase(`${findFamilyData.firstName} ${findFamilyData.lastName}`),
                      isCaregiver: false
                  }
                : {
                      epostPatientNum: currentValue.epostPatientNum,
                      currentBalance: currentValue.currentBalance,
                      patientName: convertToTitleCase(`${paymentHistoryV2.firstName} ${paymentHistoryV2.lastName}`),
                      isCaregiver
                  };
        });
    }, [paymentHistoryV2, familyProfileData, profileObject]);

    const getUserBalance = useCallback(() => {
        if (debitFamilyBalance && debitFamilyBalance > 0) {
            return {
                balanceValue: debitFamilyBalance,
                onClickHandler: handleMakePaymentFullBalanceModal
            };
        } else if (!profileObject?.isCaregiver && creditBalance && creditBalance < 0) {
            return {
                balanceValue: creditBalance,
                onClickHandler: noop
            };
        }
        return null;
    }, [debitFamilyBalance, creditBalance, profileObject]);

    const handleMakePaymentByMemberModal = (patientName: string, amount: number, epostPatientNum: string) => {
        dispatch(
            openModalComponent({
                hasDefaultFooter: false,
                hasCustomContent: true,
                hasModalHeader: false,
                hasCustomHeader: true,
                content: (
                    <MakePayment
                        paymentInformation={{
                            isCaregiver: false,
                            dependentName: patientName,
                            amountDue: amount,
                            epostPatientNum
                        }}
                        fetchPaymentData={() => fetchPaymentsHistory()}
                    />
                ),
                variation: 'small',
                isCentered: true,
                backdrop: 'static',
                onClose: () => {
                    dispatch(closeModalComponent());
                }
            })
        );
    };

    const handleMakePaymentFullBalanceModal = (isCaregiver?: boolean) => {
        dispatch(
            openModalComponent({
                hasDefaultFooter: false,
                hasCustomContent: true,
                hasModalHeader: false,
                hasCustomHeader: true,
                content: (
                    <MakePayment
                        paymentInformation={{
                            isCaregiver: isCaregiver ? profileObject?.isCaregiver : false,
                            amountDue: debitFamilyBalance as number,
                            epostPatientNum: profileObject?.epostPatientNum as string,
                            isTotalPayment: isTotalPayment
                        }}
                        fetchPaymentData={() => fetchPaymentsHistory()}
                    />
                ),
                variation: 'small',
                isCentered: true,
                backdrop: 'static',
                onClose: () => {
                    dispatch(closeModalComponent());
                }
            })
        );
    };

    const sections = useMemo(() => {
        const balanceByMembersData = getBalanceByMemberData();

        const balanceByMember = {
            heading: t('pages.profile.balanceByMember.eyebrowText'),
            children: (
                <div className="balance-container">
                    <BalanceByMemberList onClickPay={handleMakePaymentByMemberModal} members={balanceByMembersData} />
                </div>
            ),
            suppressChildrenContainer: true
        };

        const paymentHistory = {
            heading: t(`pages.profile.paymentHistory.heading`),
            children: (
                <div className="payment-history-container">
                    <TransactionList
                        variant={TransactionCardVariant.PAYMENT}
                        transactions={paymentsList.map((payment) => parsePaymentHistoryV2Result(payment))}
                        activeTab={activeTab}
                        onNavigate={setActiveTab}
                    />
                    {!profileObject?.isCaregiver && showPagination && hasRecords && (
                        <Row className="payment-history__pagination">
                            <ButtonComponent
                                className="show-more-button"
                                type="button"
                                variant="link"
                                onClick={handleShowMore}
                                label={t('pages.profile.paymentHistory.ctas.showMore', {
                                    amount: resultsAmount,
                                    total: paymentHistoryV2?.totalRecords || 10
                                })}
                            />
                            <p className="payment-history__pagination-divider">|</p>
                            <ButtonComponent
                                className="show-more-button"
                                type="button"
                                variant="link"
                                onClick={handleShowAll}
                                label={t('pages.profile.paymentHistory.ctas.showAll')}
                            />
                        </Row>
                    )}
                </div>
            ),
            suppressChildrenContainer: true,
            showMemberTabs: true,
            activeTab: activeTab,
            onTabItemChange: setActiveTab
        };

        const userBalanceData = getUserBalance();

        const userBalance = {
            children: (
                <UserBalance
                    value={userBalanceData?.balanceValue}
                    handleOnClick={userBalanceData?.onClickHandler}
                    isLoading={isLoadingPaymentHistoryData}
                    skeletonHeight={75}
                />
            ),
            suppressChildrenContainer: true
        };

        // Temporary loadings, to avoid incorrect rendering while the dependents API is loading
        // once the API has loaded the name and lastName fields, this code will no longer be needed.
        if ((profileObject?.isCaregiver && !familyProfileData.length) || isLoadingPaymentHistoryData) {
            const createMockLoading = (height: number) => ({
                children: <BalanceByMemberList members={[]} onClickPay={noop} isLoading skeletonHeight={height} />,
                suppressChildrenContainer: true
            });

            if (profileObject?.isCaregiver) {
                return [
                    createMockLoading(75), // LoadingBalance
                    createMockLoading(200), // LoadingMembers
                    createMockLoading(500) // LoadingTransactions
                ];
            }

            return [
                createMockLoading(75), // LoadingBalance
                createMockLoading(500) // LoadingTransactions
            ];
        }

        const membersWithBalance = balanceByMembersData.filter((member) => member.currentBalance !== 0);
        if (membersWithBalance?.length === 0) {
            return [userBalance, paymentHistory];
        }

        return [userBalance, balanceByMember, paymentHistory];
    }, [
        t,
        activeTab,
        isLoadingPaymentHistoryData,
        paymentHistoryV2,
        familyProfileData,
        profileObject,
        debitFamilyBalance,
        creditBalance,
        getBalanceByMemberData,
        handleMakePaymentByMemberModal
    ]);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.paymentHistory.eyebrowText`)}
            title={t(`pages.profile.paymentHistory.title`)}
            suppressChildrenContainer={true}
            sections={sections}
        />
    );
};

export default PaymentHistory;

export const query = graphql`
    query PaymentHistoryData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
