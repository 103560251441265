import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

// UI Kit
import Button from 'ui-kit/button/button';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Types
import { CreditCardPayload } from 'types/card';

// Utils
import { ellipsify } from 'util/cart';
import { addZero } from 'util/number';
import { getCreditCardEnding } from 'util/payments';
import { convertToTitleCase } from 'util/string';

// Hooks
import useWindowDimensions from 'hooks/useWindowDimensions';

// HOC
import withSkeleton from 'hoc/withSkeleton';

// Styles
import './payment-information.style.scss';

interface PaymentInformationProps {
    title?: string;
    error?: string;
    shouldHidePaymentType?: boolean;
    showPaymentMethodDisclaimer?: boolean;
    selectedPaymentMethod?: CreditCardPayload;
    creditCardsData?: CreditCardPayload[];
    dataGAFormStepName?: string;
    onChange: () => void;
    onAddNewMethod: () => void;
}

export const handleEllipsis = (label: string, width: number): string => {
    if (width < 468 && label.length > 10) {
        return ellipsify(convertToTitleCase(label), 10);
    } else return convertToTitleCase(label);
};

const PaymentInformation: React.FC<PaymentInformationProps> = (props) => {
    const {
        selectedPaymentMethod,
        onChange,
        onAddNewMethod,
        title,
        shouldHidePaymentType = false,
        showPaymentMethodDisclaimer = false,
        error,
        dataGAFormStepName = 'Checkout'
    } = props;

    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    return (
        <>
            {selectedPaymentMethod ? (
                <div className="payment-information">
                    {title && <span className="payment-information-title">{title}</span>}
                    {selectedPaymentMethod && (
                        <div className={`payment-information-container ${!title ? 'no-padding' : ''}`}>
                            {!shouldHidePaymentType && (
                                <div className="payment-information-container-type">
                                    <small>
                                        {t(
                                            `components.medicineCabinetCart.paymentMethod.${
                                                selectedPaymentMethod.defaultCard
                                                    ? 'primaryPayment'
                                                    : 'secondaryPayment'
                                            }`
                                        )}
                                    </small>
                                </div>
                            )}
                            <div className="payment-information-container-card-details">
                                <CreditCardIcon
                                    className="payment-information-container-card-details-icon"
                                    variant={selectedPaymentMethod.cardType}
                                />
                                <div className="payment-information-container-card-details-info">
                                    <span>
                                        {handleEllipsis(selectedPaymentMethod.cardType, width)}
                                        {getCreditCardEnding(selectedPaymentMethod.cardNumber, ' ···· ')}
                                    </span>
                                    <p>
                                        {handleEllipsis(
                                            `${selectedPaymentMethod?.cardFirstName} ${selectedPaymentMethod?.cardLastName}`,
                                            width
                                        )}
                                    </p>
                                    <p>
                                        {t('components.medicineCabinetCart.paymentMethod.expiry', {
                                            month: addZero(selectedPaymentMethod.cardMonthNum),
                                            year: selectedPaymentMethod.cardYear.slice(-2)
                                        })}
                                    </p>
                                </div>
                            </div>
                            <Button
                                type="button"
                                variant="outline-primary"
                                label={t('pages.profile.membership.manageMembership.changeBtn')}
                                onClick={onChange}
                                dataGAFormName="PaymentInformation"
                                dataGAFormStepName={dataGAFormStepName}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div className="checkout-payment-cta">
                    {showPaymentMethodDisclaimer && (
                        <ToastBox variant="warning" icon="warning">
                            <Trans i18nKey={'pages.profile.membership.manageMembership.paymentMethodDisclaimer'} />
                        </ToastBox>
                    )}
                    <Button
                        plusIcon
                        IconType="secondary"
                        className="btn-dashed btn btn-link"
                        dataGAFormName="Payments"
                        label={t('pages.profile.membership.manageMembership.addNewPayment')}
                        onClick={onAddNewMethod}
                        type="button"
                        variant="text-blue"
                    />
                    <div className="checkout-payment-error">{error && <p>{error}</p>}</div>
                </div>
            )}
        </>
    );
};

export default withSkeleton(PaymentInformation);
